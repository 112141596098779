module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"F4wLkuRgbvWLfsMqJYkIS8f1F9tcl8yJ","devKey":"F4wLkuRgbvWLfsMqJYkIS8f1F9tcl8yJ","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../node_modules/@arfabrands/gatsby-theme-assembly/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"243q03wr72h0","accessToken":"Zu-DBqr2WGBFW1edgWGeOA7pIpDQsDplQgwTg4lWXAM","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},"siteMetadata":{"title":"HIKI","siteUrl":"https://www.hiki.com","description":"Sweat Products For Any Body.","social":{"facebook":"HIKIForAnyBody","instagram":"HIKI_ForAnyBody","pinterest":"","youtube":"UCizvEnsjjFgh-RgJNl8Fakw","tiktok":"@hiki_foranybody?"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
